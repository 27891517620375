import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private renderer: Renderer2
  ) {}

  private isOpen = false;

  private openClass = 'open';
  private readClass = 'read';

  private open(): void {
    this.renderer.addClass(this.topEnvelopeElement, this.openClass);

    const topElement = this.topEnvelopeElement;

    setTimeout(() => {
      this.renderer.removeChild(this.envelopeElement, topElement);
      this.renderer.insertBefore(
        this.envelopeElement,
        topElement,
        this.backElement
      );
      this.renderer.addClass(this.letterElement, this.readClass);
    }, 800);

    this.isOpen = true;
  }

  private close(): void {
    this.renderer.removeClass(this.letterElement, this.readClass);

    const topElement = this.topEnvelopeElement;
    topElement?.classList.add('open');

    setTimeout(() => {
      this.renderer.removeChild(this.envelopeElement, topElement);
      this.renderer.appendChild(this.envelopeElement, topElement);

      setTimeout(() => {
        this.renderer.removeClass(this.topEnvelopeElement, this.openClass);
      }, 50);
    }, 800);

    this.isOpen = false;
  }

  private get topEnvelopeElement(): HTMLElement | null {
    return this.document.querySelector('#top');
  }

  private get letterElement(): HTMLElement | null {
    return this.document.querySelector('#letter');
  }
  private get backElement(): HTMLElement | null {
    return this.document.querySelector('#back');
  }
  private get envelopeElement(): HTMLElement | null {
    return this.document.querySelector('#envelope');
  }

  public OnLetterClick(): void {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }
}
